import React, { useEffect, useState } from 'react';
import axios from 'axios';
import https from 'https'

import TokenCard from './components/TokenCard';
import './App.css';

const fetchSolData = async (network) => {
  try {
    const httpsAgent = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
    });

    const response = await httpsAgent.get(`https://bigcryptex.com/api/trending/network/${network}`, { timeout: 30000 })
    return (response.data);
  } catch (error) {
    console.error('Error fetching the tokens:', error);
  }
};

function App() {
  const [solTokens, setSolTokens] = useState([]);
  const [tonTokens, setTonTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    fetchSolData('ton').then(tokens => {
      setTonTokens(tokens);
      setLoading(false);
    })
  }, []);

  if (loading) {
    <div className="App">
      <h1>Loading...</h1>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return (
    <div className="App">
      {/* <h1>Solana Token List</h1>
      <div className="token-list">
        {solTokens.map(token => (
          <div key={token.address} className="col-md-3 mb-4">
            <TokenCard key={token.address} token={token} />
          </div>
        ))}
      </div> */}
      <h1>Ton Token List</h1>
      <div className="token-list">
        {tonTokens.map(token => (
          <div key={token.address} className="col-md-3 mb-4">
            <TokenCard key={token.address} token={token} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
