// src/components/TokenCard.js
import React from 'react';
import './TokenCard.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const TokenCard = ({ token }) => {
  return (
    <div className="card shadow-sm rounded h-100">
      <img src={token.image_url} alt={token.name} className="card-img-top custom-image" />
      <div className="card-body d-flex flex-column">
        <h5 className="card-title text-wrap">{token.name}</h5>
        <p className="card-text text-wrap"><strong>Address:</strong> {token.address}</p>
        <p className="card-text text-wrap"><strong>Symbol:</strong> {token.symbol}</p>
        <p className="card-text text-wrap"><strong>Description:</strong> {token.description}</p>
        <p className="card-text text-wrap"><strong>Creation Date:</strong> {new Date(token.createdAt).toLocaleDateString()}</p>
        <p className="card-text text-wrap"><strong>Market Cap:</strong> {token.marketCap}</p>
        <p className="card-text text-wrap"><strong>FDV:</strong> {token.fdv}</p>
        <p className="card-text text-wrap"><strong>Base Price:</strong> {token.basePriceQuote}</p>
      </div>
    </div>
  );
};

export default TokenCard;
